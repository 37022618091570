import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

import APaper from '../components/01-atoms/a-paper'
import ALink from '../components/01-atoms/a-link'

import SimpleTemplateWrapper from '../components/04-templates/t-simple-template-wrapper'
import { contactInfo } from '../../site-config'

const styles = {
  paragraph: {
    fontSize: 16,
  },
}

/** OCompanyDetail used to display company details */
class ContactPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props

    return (
      <SimpleTemplateWrapper showHeadlineBar headline="Kontaktieren Sie uns">
        <APaper>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4} md={4}>
              <Typography component="p" paragraph className={classes.paragraph}>
                <strong>Ihr Kontakt</strong>
                <br />
                {contactInfo.contactPerson}
                <br />
                {contactInfo.contactPersonPhone}
                <br />
                E-Mail:{' '}
                <ALink to={`tel:${contactInfo.contactPersonEmail}`} noButton>
                  {contactInfo.contactPersonEmail}
                </ALink>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              {contactInfo.supportEmail && (
                <Typography
                  component="p"
                  paragraph
                  className={classes.paragraph}
                >
                  <strong>Service und Support</strong>
                  <br />
                  E-Mail:{' '}
                  <ALink to={`mailto:${contactInfo.supportEmail}`} noButton>
                    {contactInfo.supportEmail}
                  </ALink>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography component="p" paragraph className={classes.paragraph}>
                {contactInfo.companyName}
                <br />
                {contactInfo.companyAdress}
                <br />
                {contactInfo.companyPhone}
              </Typography>
            </Grid>
          </Grid>
        </APaper>
      </SimpleTemplateWrapper>
    )
  }
}

ContactPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactPage)
